import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/style.css'; // Your custom CSS file
import logo from '../images/logo.png'; // Import your logo image

const Navbar = () => {
  const [sidebarVisible, setSidebarVisible] = useState(false);

  const showSidebar = () => {
    setSidebarVisible(true);
  };

  const hideSidebar = () => {
    setSidebarVisible(false);
  };

  return (
    <>
      <div className="top-bar">
        <div className="container">
          <div className="row">
            <div className="col">
              <p className="top-bar-text">
                Call Us: <span className="phone-number">(954) 371-0069</span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <nav className="custom-navbar navbar navbar-expand-md sticky-top" aria-label="Furni navigation bar">
        <div className="container">
          <Link className="navbar-brand" to="/">
            <img src={logo} className="img-fluid" alt="Furni Logo" />
          </Link>
          <button
            className="navbar-toggler d-md-none"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarsFurni"
            aria-controls="navbarsFurni"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={() => setSidebarVisible(!sidebarVisible)}
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarsFurni">
            <ul className="custom-navbar-nav navbar-nav ms-auto mb-2 mb-md-0">
              <li className="nav-item">
                <Link className="nav-link" to="/">Home</Link>
              </li>
              <li className="nav-item dropdown">
                <Link
                  className="nav-link dropdown-toggle"
                  to="/programs"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Programs
                </Link>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li>
                    <Link className="dropdown-item" to="/sba">SBA Loans</Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/real-estate">Real Estate Acquisition Loans</Link>
                  </li>
                </ul>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/about">About Us</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/testimonials">Testimonials</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/contact">Contact Us</Link>
              </li>
              <li className="nav-item dropdown">
                <Link
                  className="nav-link dropdown-toggle"
                  to="/resources"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Resources
                </Link>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li>
                    <Link className="dropdown-item" to="/usdamap">USDA Eligibility Map</Link>
                  </li>
                </ul>
              </li>
            </ul>

            <Link to="/apply">
              <button className="apply-now-btn">APPLY NOW</button>
            </Link>
          </div>
        </div>
      </nav>

      {sidebarVisible && (
        <ul className="sidebar d-md-none">
          <li onClick={hideSidebar}>
            <a href="#">
              <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368">
                <path d="m249-207-42-42 231-231-231-231 42-42 231 231 231-231 42 42-231 231 231Z"/>
              </svg>
            </a>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/">Home</Link>
          </li>
          <li className="nav-item dropdown">
                <Link
                  className="nav-link dropdown-toggle"
                  to="/programs"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Programs
                </Link>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li>
                    <Link className="dropdown-item" to="/sba">SBA Loans</Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/real-estate">Real Estate Acquisition Loans</Link>
                  </li>
                </ul>
              </li>
          <li className="nav-item">
            <Link className="nav-link" to="/about">About Us</Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/testimonials">Testimonials</Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/contact">Contact Us</Link>
          </li>
          <li className="nav-item dropdown">
                <Link
                  className="nav-link dropdown-toggle"
                  to="/resources"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Resources
                </Link>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li>
                    <Link className="dropdown-item" to="/usdamap">USDA Eligibility Map</Link>
                  </li>
                </ul>
              </li>
        </ul>
      )}
    </>
  );
};

export default Navbar;