import React, { useState } from 'react';
import { MapContainer, TileLayer, GeoJSON, useMap } from 'react-leaflet';
import { GeoSearchControl, OpenStreetMapProvider } from 'leaflet-geosearch';

import 'leaflet/dist/leaflet.css';
import 'leaflet-geosearch/dist/geosearch.css';
import geoJsonData from './RBS_Ineligible.json'; // Import GeoJSON data from file
import '../css/style.css'; // Import your CSS file

const tileLayers = {
  Light: "https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png",
  Dark: "https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png",
  Satellite: "https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png",
};

const SearchControl = () => {
  const map = useMap();
  React.useEffect(() => {
    const provider = new OpenStreetMapProvider();

    const searchControl = new GeoSearchControl({
      provider,
      style: 'bar',
      showMarker: true,
      autoClose: true,
      retainZoomLevel: false,
      animateZoom: true,
    });

    map.addControl(searchControl);

    return () => map.removeControl(searchControl);
  }, [map]);

  return null;
};

const MapTypeControl = ({ onChange }) => {
  const handleChange = (event) => {
    onChange(event.target.value);
  };

  return (
    <div className="map-type-control">
      <label htmlFor="mapType" style={{ color: 'white', marginRight: '8px' }}>Map Type:</label>
      <select id="mapType" onChange={handleChange}>
        <option value="Light">Light</option>
        <option value="Dark">Dark</option>
        <option value="Satellite">Satellite</option>
      </select>
    </div>
  );
};

const Map = () => {
  const [selectedTileLayer, setSelectedTileLayer] = useState(tileLayers.Light);

  const handleMapTypeChange = (type) => {
    setSelectedTileLayer(tileLayers[type]);
  };

  return (
    <div className="map-container">
      <MapContainer
        center={[37.0902, -95.7129]} // Centered on the US
        zoom={4} // Set the initial zoom level
        minZoom={4} // Prevent zooming out beyond this level
        maxZoom={18} // Allow zooming in but not too much
        style={{ height: '500px', width: '100%' }} // Only set height inline
      >
        <TileLayer
          url={selectedTileLayer}
          attribution="&copy; <a href='https://www.openstreetmap.org/copyright'>OpenStreetMap</a> contributors &copy; <a href='https://carto.com/attributions'>CARTO</a>"
        />
        <GeoJSON
          data={geoJsonData}
          style={{ fillColor: 'lightcoral', color: 'gray', weight: 0.5 }} // Faint outlines
        />
        <SearchControl />
        <MapTypeControl onChange={handleMapTypeChange} />
      </MapContainer>
      <div className="map-info">
        USDA Rural Development Program Eligibility Map (2020 Census) – Brought to You by Yes Financial Service. Updated and Launched July 2024.
      </div>
    </div>
  );
};

export default Map;
