import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/TopSlide';
import LastFooter from './components/BottomSlide';
import Home from './components/Home';
import Shop from './components/Shop';
import Apply from './components/Apply';
import About from './components/About';
import SBALoans from './components/SBAloans';
import UsdaMap from './components/UsdaMap';
import RealEstate from './components/RealEstate';
import ScrollToTop from './components/ScrollToTop'; // Import the ScrollToTop component
import './css/bootstrap.min.css';
import './css/tiny-slider.css';
import './css/style.css';

function App() {
  return (
    <Router>
      <ScrollToTop /> {/* Include the ScrollToTop component */}
      <div>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/shop" element={<Shop />} />
          <Route path="/apply" element={<Apply />} />
          <Route path="/about" element={<About />} />
          <Route path="/sba" element={<SBALoans />} />
          <Route path="/usdamap" element={<UsdaMap />} />
          <Route path="/real-estate" element={<RealEstate />} />
        </Routes>
        <Footer />
        <LastFooter />
      </div>
    </Router>
  );
}

export default App;